import Vue from 'vue'
import { UserVuetifyPreset } from 'vuetify'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'
import colors from 'vuetify/lib/util/colors'

export const config = {
  directives: {
    Touch
  }
}

const defaultColors = {
  primary: '#432CA0',
  error: '#FF0000',
  'sm-bronze': '#A69711',
  purple: '#765FD3',
  'light-green': {
    ...colors.lightGreen,
    lighten6: '#F1EFFB'
  },
  indigo: {
    ...colors.indigo,
    darken3: '#432CA0'
  },
  cyan: {
    ...colors.cyan,
    darken1: '#2db4af'
  },
  pink: {
    ...colors.pink,
    darken3: '#9F1919'
  },
  teal: '#0BC19C',
  'viridian-green': '#25938F',
  accent: '#82B1FF',
  info: '#2196F3',
  success: '#4CAF50',
  brightSuccess: '#25CD2B',
  warning: '#FFEA47',
  secondary: colors.grey.darken1,
  'blue-magenta': '#CEC8E6'
}

export const opts: Partial<UserVuetifyPreset> = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ...defaultColors
      },
      dark: { // colors before re-branding
        ...defaultColors,
        primary: '#0085CA',
        error: '#F93943',
        'sm-bronze': '#cc982d',
        purple: '#9e52bf',
        indigo: {
          ...colors.indigo,
          darken3: '#283593'
        },
        pink: {
          ...colors.pink,
          darken3: '#ad1457'
        },
        teal: '#009688',
        'viridian-green': '#0085CA',
        cyan: {
          ...colors.cyan,
          darken1: '#00acc1'
        },
        'light-green': {
          ...colors.lightGreen,
          lighten5: '#f6faf3'
        },
        warning: '#FFC107',
        info: '#2196F3'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg' as any,
    values: {
      checkboxOff: 'ico-sm-checkbox-unchecked',
      checkboxOn: 'ico-sm-checkbox-checked',
      next: 'ico-sm-arrow-right',
      prev: 'ico-sm-arrow-left',
      radioOn: 'ico-sm-radiobutton-checked',
      radioOff: 'ico-sm-radiobutton-unchecked'
    }
  },
  breakpoint: {
    thresholds: {
      xs: 720,
      sm: 960,
      md: 1064,
      lg: 1360
    }
  }
}

Vue.use(Vuetify, config)

export default new Vuetify(opts)
